<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form style="display:flex;flex-direction:column" label-position="right" class="demo-form-inline" :inline="true">
        <el-form-item>
          <el-button type="primary" @click="addRow()">
						<i class="el-icon-plus"></i>
						新增
					</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-loading="loading">
      <el-table :data="tableData" style="width: 100%;margin-top: 20px;">
        <el-table-column type="index" align="center" height show-overflow-tooltip></el-table-column>
        <el-table-column prop="sort" label="排序" align="center" height show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="任务类型" align="center" height show-overflow-tooltip></el-table-column>
        <el-table-column prop="point" label="积分" align="center" height show-overflow-tooltip></el-table-column>
        <el-table-column prop="unit" label="单位" align="center" height show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="300" align="center">
						<template slot-scope="scope">
							<el-button type="primary" size="small" @click="editRow(scope.row)"><i class="el-icon-edit"></i> 修改</el-button>
							<el-button type="danger" size="small" @click="deleteRow(scope.row)"><i class="el-icon-delete"></i> 删除</el-button>
						</template>
					</el-table-column>
      </el-table>
    </div>
    <el-dialog :title="dialogTitle" width='650px' :visible.sync="dialogVisible" :close-on-click-modal="false" :before-close="handleClose">
      <div class="content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="服务项目" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入服务项目"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
          </el-form-item>
          <el-form-item label="积分" prop="point">
            <el-input v-model="ruleForm.point" placeholder="请输入积分"></el-input>
          </el-form-item>
          <el-form-item label="积分单位" prop="unit">
            <el-input v-model="ruleForm.unit" placeholder="请输入积分单位"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('ruleForm')">确定</el-button>
            <el-button @click="closeDialog">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getVolServiceType, createVolServiceType, deleteVolServiceType} from '@/api/showLove'
import { sureMessageBox }　from '@/utils/common'
export default {
  data () {
    return {
      tableData: [],
      loading: true,
      dialogTitle: '',
      dialogVisible: false,
      addFlag: false,
      ruleForm: {
        name: '',
        sort: '',
        point: '',
        unit: '',
      },
      rules: {
        name: [
          {required: true, message: "请输入服务项目", trigger: "change"},
        ],
        sort: [
          {required: true, message: "请输入排序", trigger: "change"},
        ],
        point: [
          {required: true, message: "请输入积分", trigger: "change"},
        ],
        unit: [
          {required: true, message: "请输入积分单位", trigger: "change"},
        ],
      },
      tijiao: false,
    };
  },

  components: {},

  computed: {},

  mounted(){
    this.getList();
  },

  methods: {
    getList() {
      getVolServiceType().then(res => {
        this.loading = false;
        if(res.result == 200) {
          this.tableData = res.detail.list;
        }
      })
    },
    addRow() {
      this.dialogTitle = "新增";
      this.dialogVisible = true;
      this.addFlag = true;
    },
    editRow(row) {
      this.dialogTitle = "修改";
      this.ruleForm = {
        id: row.id,
        name: row.name,
        sort: row.sort,
        point: row.point,
        unit: row.unit
      }
      this.dialogVisible = true;
      this.addFlag = false;
    },
    handleClose(done) {
      this.closeDialog();
      done();
    },
    closeDialog(type) {
      this.dialogVisible = false;
      this.$refs.ruleForm.resetFields();
      this.ruleForm = {
        id: '',
        name: '',
        sort: '',
        point: '',
        unit: '',
      }
      if(type == 'edit') {
        this.getList();
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认添加该社员服务项目?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.tijiao = true;
            if(this.addFlag) {
              // 新增
              createVolServiceType({
                name: this.ruleForm.name,
                sort: this.ruleForm.sort,
                point: this.ruleForm.point,
                unit: this.ruleForm.unit
              }).then(res => {
                this.tijiao = false;
                if (res.result == 200) {
                  this.$message.success("添加成功");
                  this.closeDialog('edit');
                } else {
                  this.$message.error(res.description);
                }
              })
            } else {
              // 修改
              createVolServiceType({
                id: this.ruleForm.id,
                name: this.ruleForm.name,
                sort: this.ruleForm.sort,
                point: this.ruleForm.point,
                unit: this.ruleForm.unit
              }).then(res => {
                this.tijiao = false;
                if (res.result == 200) {
                  this.$message.success("修改成功");
                  this.closeDialog('edit');
                } else {
                  this.$message.error(res.description);
                }
              })
            }
          })
        }
      })
    },
    deleteRow(row) {
      sureMessageBox('是否确认删除该社员服务项目？').then(res=>{
        deleteVolServiceType({
          id : row.id
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功')
            this.getList();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
  }
}

</script>
<style lang='scss' scoped>

</style>